import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import FAQs from '../../components/FAQs'

import { Box, Flex } from 'grid-styled'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info,
} from '..'
import Form from '../../components/Form'
import {
  Text,
  GallerySection,
  Content,
  MainContent,
} from '../../components/styles'
import Button from '../../components/Button'
import ContentCard from '../../components/ContentCard'
import Hero from '../../components/Hero'
import { Video } from '../../components/procedures'
import GatsbyImage from 'gatsby-image'
import CTA from '../../components/CTA'

class Comp extends React.Component {
  state = {
    active: 'juniorDoctors',
  }
  makeVisible = (id: string) => {
    const section = document.getElementById(id)
    const others = document.getElementsByClassName('article-stages')
    if (section !== null) {
      Array.from(others).map((el) => (el.style.display = 'none'))
      section.style.display = 'block'
      this.setState({ active: id })
    }
  }

  render() {
    return (
      <Layout header="light">
        <Helmet>
          <title>Mortgage Advice - Parinity Financial Professionals</title>
          <meta
            name="description"
            content="Our mortgage broking advice is comprehensive and can assist you with
            all your financial needs. Contact us about our mortgage advice today!"
          />
        </Helmet>

        <HomeHeader slant={false} height="75vh">
          <figure>
            <Img fluid={this.props.data.jpBackground.childImageSharp.fluid} />
          </figure>
          <div>
            <h2>mortgage advice</h2>
            <Text size="large">
              We don’t just provide our clients great rates on their home loans,
              we provide a comprehensive lending solution. Our process removes
              the complexity and allows us to compare ‘apples with apples’ so
              you can make an informed decision.
            </Text>
            <Link to="/contact">
              <Button outline={true} color="#fff">
                Contact us
              </Button>
            </Link>
          </div>
          <FormContainer>
            <div>
              <Form title="Contact us today" showMessage={true} />
            </div>
          </FormContainer>
        </HomeHeader>

        <Content style={{ paddingTop: 100 }}>
          <h1>parinity - mortgage and lending advice</h1>

          <span>
            Our mortgage broking advice is comprehensive and can assist you with
            all your financial needs.
          </span>

          <p>
            We don’t just provide our clients great rates on their home loans,
            we provide a comprehensive lending solution. With the constant
            bombardment of advertising from various institutions, finding the
            right home loan can be a challenging task. Our process removes the
            complexity and allows us to compare ‘apples with apples’ so you can
            make an informed decision.
          </p>

          <p>
            By applying our financial planning expertise to the mortgage broking
            aspect of your financial life, we add depth to your decision making
            process in that we help you “look forward” to ensure that the
            facility you enter into now is in line with your future goals. This
            means we can make sure that you get the right structure to help you
            pay off the bad debt sooner, have the flexibility to use your equity
            for future investing and wealth creation, or maintain access to a
            contingency fund.
          </p>

          <h4>our relationships</h4>

          <p>
            Through Parinity’s relationship with Connective (our Credit Licence
            holder), we are able to access a broad panel of lenders who
            specialise in a wide variety of lending niches. As a result, you can
            be assured that we will be able to source the most appropriate
            solution for your specific lending situation.
          </p>

          <Box width="500px" style={{ margin: '0 auto' }}>
            <Img fluid={this.props.data.graphic.childImageSharp.fluid} />
          </Box>

          <h4>our offering</h4>
          <ul>
            <li>
              Buying your first home either on your own, or with the help of
              your parents{' '}
            </li>
            <li>Building your dream home from start to finish</li>
            <li>Building wealth through using your available equity</li>
            <li>Maximising your Self Managed Super Fund to buy property</li>
            <li>Structuring your loans to own your home sooner</li>
            <li>Refinancing you to get a better deal and a better structure</li>
            <li>Sourcing quality banking and finance solutions </li>
            <li>Helping you buy your lifestyle assets the right way </li>
          </ul>

          <p>
            With so many things we can help you with, why not book an
            appointment now so we can help you take control of your financial
            future.
          </p>

          <Link to="/mortgage-advice/apply">
            <Button inverted={true}>Apply online now!</Button>
          </Link>
        </Content>

        <Hero
          bgImage={this.props.data.blurredHero.childImageSharp}
          height="550px"
        >
          <h3>Want to find out more?</h3>
          <p>
            Call 07 3114 2500 or complete the enquiry form to contact us today
            and find out how we can help.
          </p>
          <Flex>
            <Button
              onClick={(e) => {
                e.preventDefault()
                Calendly.showPopupWidget('https://calendly.com/lancecheung/')
              }}
              inverted={true}
            >
              Book an appointment now!
            </Button>
          </Flex>
        </Hero>
        <AboutContact />

        <CTA
          title="Download our wealth tracking app"
          subtitle="A better tracking solution."
          button="Access Parinity Wealthdesk"
          inverted={true}
          to="https://parinity.wealthdesk.com.au/my/home"
        />
      </Layout>
    )
  }
}
export default Comp

export const query = graphql`
  query MortgageQuery {
    jpBackground: file(relativePath: { eq: "mortgage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    junior: file(relativePath: { eq: "junior.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    specialists: file(relativePath: { eq: "specialists.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    registrars: file(relativePath: { eq: "registrars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    accessories: file(relativePath: { eq: "roofing-brisbane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "cta-bg.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    graphic: file(relativePath: { eq: "mortgage-graphic.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gallery: allFile(filter: { relativeDirectory: { eq: "gallery/patios" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
